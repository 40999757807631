import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC0cg-4VbiAFgGmunSwEHSS_UqvYnHQQ8I",
  authDomain: "iodine-729.firebaseapp.com",
  projectId: "iodine-729",
  storageBucket: "iodine-729.appspot.com",
  messagingSenderId: "831656692485",
  appId: "1:831656692485:web:1324dee0507328b75ec56c",
  measurementId: "G-0PDS12Z2LE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function event(name: string, params: Record<string, string | number> = {}) {
  logEvent(analytics, name, params);
}