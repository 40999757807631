import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Triples} from "./Triples";

interface ReplayRouteParams {
  id: string,
  [key: string]: string,
}

interface ReplayStep {
  numberKind: number;
  pos: {
    row: number;
    col: number;
  }
}

export interface ReplayData {
  id: string;
  score: number;
  finalTiles: number[][];
  nextTileValues: number[];
  steps: ReplayStep[];
}

type ResWrapper<T> = {
  success: boolean;
  successInfo?: T;
  errorInfo?: string;
}

export const Replay: React.FC = props => {
  const params = useParams<ReplayRouteParams>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ReplayData>()
  const [error, setError] = useState('')

  useEffect(() => {
    setLoading(true)
    fetch(`https://www.xiongdianpku.com/api/triples/replay/${params.id}`).then(async res => {
      setLoading(false);
      if (res.ok) {
        const json: ResWrapper<ReplayData> = await res.json();
        if (json.success && json.successInfo) {
          setData(json.successInfo);
          setError('');
          const lang = {
            zh: 'zh',
            en: 'en',
          }[navigator.language.split('-')[0]] || 'en';
          document.title = lang === 'zh' ?
            '我在「三生万物」中获得了 ' + json.successInfo.score + ' 分，来看看我的回放吧 >' :
            'I\'ve got ' + json.successInfo.score + ' scores in Triples-729, watch my game replay >'
        } else {
          setData(undefined);
          setError(json.errorInfo || `Error fetching replay record for ${params.id}`);
        }
      } else {
        setData(undefined);
        setError(`Error fetching replay record for ${params.id}`);
      }
    }).catch(res => {
      setLoading(false);
      setData(undefined);
      setError(`Error fetching replay record for ${params.id}`);
    })
  }, [params.id])

  return (
    <div style={{
      ...loading ? {
        opacity: 0.5,
        pointerEvents: 'none',
      } : {}
    }}>
      <Triples replayData={data} key={data?.id || 'placeholder'}/>
    </div>
  )
}