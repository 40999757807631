import React from "react";

export const Support: React.FC = () => {
  return (
    <div style={{ padding: 24 }}>
      <h1>Game Rules</h1>
      <p>You will get a set of random numbers, you can put a number in a cell, and three same numbers will merge into a larger number if they get neighbouring</p>
      <p>Your goal is to build as more as you can before you run out of cells</p>
      <h1>Contact Me</h1>
      <p>For any technical issues or game advices, you can reach me at x@xiongdianpku.com</p>
    </div>
  )
}