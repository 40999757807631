import React, {useEffect, useRef} from "react";
import './Triples.css';
import {GuideStatus, TripleStatus, useTripleReducer} from "./TripleReducer";
import {CELL_MARGIN, CELL_SIZE, Tile, TileType} from "./Tile";
import {Guide} from "./Guide";
// @ts-ignore
import app_store from './app_store.svg';
import {event} from "./Analytics";
import {ReplayData} from "./Replay";

interface TriplesProps {
  replayData?: ReplayData,
}

export const Triples: React.FC<TriplesProps> = (props) => {
  const [store, dispatcher] = useTripleReducer(props.replayData);
  const size = props.replayData?.finalTiles.length || 4;
  const onResize = () => {
    const scale = Math.min(
      Math.min(window.innerWidth / 499, 1),
      Math.min(window.innerHeight / 665, 1)
    );
    const root = document.getElementById('root');
    (root!.style as any).zoom = `${scale}`;
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    }
  });

  const replayStepIndex = useRef(0)

  const replayNext = () => {
    setTimeout(() => {
      if (replayStepIndex.current >= props.replayData!.steps.length) {
        return;
      }
      console.log('replay next', store.nextTileValues, props.replayData!.steps[replayStepIndex.current].pos.row, props.replayData!.steps[replayStepIndex.current].pos.col);
      dispatcher({
        action: 'set_tile',
        row: props.replayData!.steps[replayStepIndex.current].pos.row,
        col: props.replayData!.steps[replayStepIndex.current].pos.col,
      })
      replayStepIndex.current++
    }, 500);
  };

  useEffect(() => {
    if (store.guideStatus === GuideStatus.FIRST_TAP) {
      event('guide_show');
    }
  }, []);

  const displayTiles: Tile[] = [];
  for (let row = 0; row < size; row++) {
    for (let col = 0; col < size; col++) {
      displayTiles.push({
        row,
        col,
        value: 0,
        type: TileType.BG,
      });
    }
  }

  for (let row = 0; row < size; row++) {
    for (let col = 0; col < size; col++) {
      if (store.tiles[row][col]) {
        displayTiles.push({
          row,
          col,
          value: store.tiles[row][col],
          type: TileType.VALUE,
          transition: store.status === TripleStatus.TRANSITING && store.transition?.tiles.find(({ row: transitionRow, col: transitionCol }) => {
            return transitionRow === row && transitionCol === col;
          }) ? {
            row: store.transition.targetRow,
            col: store.transition.targetCol,
          } : undefined,
        })
      }
    }
  }

  if (store.previewTilePosition) {
    const {row, col} = store.previewTilePosition;
    displayTiles.push({
      row,
      col,
      value: store.nextTileValues[0],
      type: TileType.PREVIEW,
    })
  }

  const getMaxTile = (tiles: number[][]) => {
    return tiles.reduce((max, cur) => {
      return Math.max(max, cur.reduce((max, cur) => {
        return Math.max(max, cur);
      }, 0))
    }, 0);
  }

  useEffect(() => {
    if (store.status === TripleStatus.TRANSITING) {
      setTimeout(() => dispatcher({
        action: 'finish_transition'
      }), 200);
    } else if (store.nextTileTransiting) {
      setTimeout(() => dispatcher({
        action: 'finish_next_transition'
      }), 200);
    } else if (store.status === TripleStatus.SETTING_TILE && props.replayData) {
      replayNext();
    }
  }, [store]);

  return (
    <div className={'container'}>
      <div className={'content'}>
        {props.replayData ? <div>
          <p className={'game-title'}>Game Replay</p>
          <p className={'game-sub-title'}>{`Score ${props.replayData.score} / Max Tile ${getMaxTile(props.replayData.finalTiles)}`}</p>
        </div>: null}
        <div className={'header'}>
          <div className={'next-tiles'}>
            {store.nextTileValues.slice(0, 3).reverse().map((value, index) => (
              <Tile
                key={`next-${index}`}
                tile={{
                  row: 0,
                  col: index - 1,
                  value: value,
                  type: index === 2 ? TileType.NEXT_0 : TileType.NEXT_1,
                  transition: store.nextTileTransiting ? {
                    row: 0,
                    col: index,
                  } : undefined
                }}
              />
            ))}
          </div>
          <div className={'right'}>
            <div className={'scores'}>
              <div className={'score'}>
                <div className={'title'}>SCORE</div>
                <div className={'num'}>{store.score}</div>
              </div>
              <div className={'score'}>
                <div className={'title'}>BEST</div>
                <div className={'num'}>{store.bestScore}</div>
              </div>
            </div>
            <div className={'ad'}>
              <a href={'https://apps.apple.com/app/triples-729/id1632777736'}><img src={app_store} className={'qr-code'} /></a>
            </div>
          </div>
        </div>
        <div className={'table'} style={
          props.replayData ? {pointerEvents: 'none'} : {}
        }>
          {
            displayTiles.map((tile) => {
              return (
                <Tile
                  key={`${tile.row}-${tile.col}-${tile.type}`}
                  scale={(CELL_MARGIN * 5 + CELL_SIZE * 4) / (CELL_MARGIN * (store.tiles.length + 1) + CELL_SIZE * store.tiles.length)}
                  tile={tile}
                  onClick={() => dispatcher({
                    action: 'set_tile',
                    row: tile.row,
                    col: tile.col,
                  })}
                />
              );
            })
          }
          {
            store.guideStatus !== GuideStatus.COMPLETED && (
              <Guide store={store} dispatcher={dispatcher}/>
            )
          }
          {
            store.status === TripleStatus.GAME_OVER && (
              <div className={'game-over'}>
                <div className={'title'}>
                  Game Over!
                </div>
                <div className={'replay'} onClick={() => dispatcher({
                  action: 'replay'
                })}>
                  Try Again
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}