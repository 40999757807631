import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Triples} from "./Triples";
// @ts-ignore
import FastClick from 'fastclick';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {event} from "./Analytics";
import {Privacy} from "./Privacy";
import {Support} from "./Support";
import {Replay} from "./Replay";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path={'/'} element={<Triples />}/>
      <Route path={'/privacy'} element={<Privacy/>}/>
      <Route path={'/support'} element={<Support/>}/>
      <Route path={'/replay/:id'} element={<Replay/>}/>
    </Routes>
  </BrowserRouter>
);

FastClick.attach(document.body);
event('game_start', {replay: 0});