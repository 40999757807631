import {useReducer} from "react";
import {event} from "./Analytics";
import {ReplayData} from "./Replay";

export enum TripleStatus {
  SETTING_TILE,
  TRANSITING,
  GAME_OVER,
}

export enum GuideStatus {
  FIRST_TAP,
  SECOND_TAP,
  THIRD_TAP,
  NEXT_TIP,
  GOAL,
  COMPLETED,
}

export type TripleState = {
  tiles: number[][],
  score: number,
  bestScore: number,
  nextTileValues: number[],
  nextTileTransiting: boolean,
  transition: {
    tiles: Array<{row: number, col: number}>,
    targetRow: number,
    targetCol: number,
  } | null,
  previewTilePosition: {row: number, col: number} | null,
  status: TripleStatus,
  tileCount: number,
  guideStatus: GuideStatus,
};

const GUIDE_STATUS_STORAGE_KEY = 'GUIDE_STATUS_STORAGE_KEY';
const GAME_BEST_SCORE_STORAGE_KEY = 'GAME_BEST_SCORE_STORAGE_KEY';

const initialStateGenerator = (replayData?: ReplayData): TripleState => {
  const nextTileValues = [];
  if (replayData) {
    replayData.steps.forEach(({ numberKind }) => {
      nextTileValues.push(numberKind);
    });
    nextTileValues.push(...replayData.nextTileValues);
  } else {
    nextTileValues.push(1, 1, 1);
  }

  const size = replayData?.finalTiles.length || 4;

  return {
    tiles: Array.from( { length: size }).map(() =>
      Array.from({ length: size }).fill(0)
    ) as number[][],
    score: 0,
    bestScore: replayData ? replayData.score : parseInt(localStorage.getItem(GAME_BEST_SCORE_STORAGE_KEY) || '0') || 0,
    transition: null,
    nextTileValues,
    nextTileTransiting: false,
    previewTilePosition: null,
    status: TripleStatus.SETTING_TILE,
    tileCount: 0,
    guideStatus: replayData ? GuideStatus.COMPLETED : localStorage.getItem(GUIDE_STATUS_STORAGE_KEY) ? GuideStatus.COMPLETED : GuideStatus.FIRST_TAP,
  }
}

export type TripleAction = {
  action: 'set_tile',
  row: number,
  col: number,
} | {
  action: 'finish_transition',
} | {
  action: 'finish_next_transition',
} | {
  action: 'guide_next',
} | {
  action: 'replay'
}

function getTripleTiles (state: TripleState, initRow: number, initCol: number) {
  const queue = [{ row: initRow, col: initCol }];
  const res: Array<{ row: number, col: number }> = [];
  const visited: Record<string, boolean> = {};
  const size = state.tiles.length;
  while (queue.length) {
    const { row, col } = queue.shift()!;
    res.push({ row, col });
    visited[`${row}-${col}`] = true;
    const checkPoints = [{
      row: row - 1,
      col,
    }, {
      row: row,
      col: col - 1,
    }, {
      row: row + 1,
      col,
    }, {
      row: row,
      col: col + 1,
    }].filter(({ row, col }) =>
      row >= 0 && row < size &&
      col >= 0 && col < size &&
      state.tiles[row][col] === state.tiles[initRow][initCol] &&
      !visited[`${row}-${col}`]
    );
    queue.push(...checkPoints);
  }
  return res;
}

export function tripleReducer(lastState: TripleState, action: TripleAction) {
  const state = {...lastState};
  if (state.guideStatus !== GuideStatus.COMPLETED && action.action === 'guide_next') {
    state.guideStatus++;
    if (state.guideStatus === GuideStatus.COMPLETED) {
      event('guide_completed');
      localStorage.setItem(GUIDE_STATUS_STORAGE_KEY, '1');
    }
    return state;
  }
  switch (state.status) {
    case TripleStatus.SETTING_TILE:
      if (action.action === 'set_tile') {
        if (state.nextTileTransiting) {
          return state;
        }
        if (state.guideStatus === GuideStatus.FIRST_TAP) {
          if (action.row !== 0 || action.col !== 0) {
            return state;
          }
        } else if (state.guideStatus === GuideStatus.SECOND_TAP) {
          if (action.row !== 0 || action.col !== 1) {
            return state;
          }
        } else if (state.guideStatus === GuideStatus.THIRD_TAP) {
          if (action.row !== 0 || action.col !== 2) {
            return state;
          }
        }
        if (state.tiles[action.row][action.col] != 0) {
          return state;
        }
        state.tiles[action.row][action.col] = state.nextTileValues[0];
        state.tileCount++;
        state.score++;
        state.previewTilePosition = null;
        state.nextTileTransiting = true;
        const triples = getTripleTiles(state, action.row, action.col);
        if (triples.length >= 3) {
          state.transition = {
            tiles: triples,
            targetRow: action.row,
            targetCol: action.col,
          }
          state.status = TripleStatus.TRANSITING;
        } else {
          if (state.tiles.flat().filter(num => num === 0).length === 0) {
            // no empty position
            const maxTile = state.tiles.flat().reduce((max, cur) => Math.max(max, cur));
            event('game_over', {score: state.score, max_tile: maxTile});
            state.status = TripleStatus.GAME_OVER;
          }
        }
        if (state.guideStatus === GuideStatus.FIRST_TAP) {
          state.guideStatus = GuideStatus.SECOND_TAP;
        } else if (state.guideStatus === GuideStatus.SECOND_TAP) {
          state.guideStatus = GuideStatus.THIRD_TAP;
        } else if (state.guideStatus === GuideStatus.THIRD_TAP) {
          state.guideStatus = GuideStatus.NEXT_TIP;
        }
      } else if (action.action === 'finish_next_transition') {
        if (state.nextTileTransiting) {
          state.nextTileTransiting = false;
          state.nextTileValues.shift();
          const maxTile = state.tiles.flat().reduce((max, cur) => Math.max(max, cur));
          state.nextTileValues.push(Math.max(Math.min(Math.pow(3, Math.floor(-Math.log(Math.random()) / Math.log(3))), Math.round(maxTile / 3)), 1));
        }
      }
      break;
    case TripleStatus.TRANSITING:
      if (action.action === 'finish_transition' && state.transition) {
        const oriValue = state.tiles[state.transition.targetRow][state.transition.targetCol];
        state.transition.tiles.forEach(({ row, col }) => {
          state.tiles[row][col] = 0;
        });
        state.tiles[state.transition.targetRow][state.transition.targetCol] = oriValue * 3;
        state.score += oriValue * state.transition.tiles.length;
        event('tile_merge', {value: oriValue * 3});
        const triples = getTripleTiles(state, state.transition.targetRow, state.transition.targetCol);
        if (triples.length >= 3) {
          state.transition = {
            tiles: triples,
            targetRow: state.transition.targetRow,
            targetCol: state.transition.targetCol,
          }
          state.status = TripleStatus.TRANSITING;
        } else {
          state.status = TripleStatus.SETTING_TILE;
        }
      }
      break;
    case TripleStatus.GAME_OVER:
      if (action.action === 'replay') {
        event('game_start', {replay: 1});
        return initialStateGenerator();
      }
      break;
  }
  if (state.score > state.bestScore) {
    state.bestScore = state.score;
    localStorage.setItem(GAME_BEST_SCORE_STORAGE_KEY, `${state.bestScore}`);
  }
  return state;
}

export function useTripleReducer(replayData?: ReplayData) {
  return useReducer(tripleReducer, initialStateGenerator(replayData));
}