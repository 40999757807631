import React from "react";

export const Privacy: React.FC = () => {
  //eslint-disable-next-line
  if (location.search === '?lang=zh') {
    return (
      <div style={{ padding: 24 }}>
        <h1>Triples 729 - 三生万物 隐私政策说明</h1>
        <p>
          本 App 收集匿名产品使用和崩溃数据，仅用于性能、体验分析，此数据不会与您的个人身份关联。
        </p>
      </div>
    );
  } else {

  }
  return (
    <div style={{ padding: 24 }}>
      <h1>Triples-729 Privacy Policy</h1>
      <p>
        This app collect anonymous data only for crashlytics and performance analyses, the data will not be associated to your identity.
      </p>
    </div>
  )
}