import React from "react";

export const CELL_SIZE = 106;
export const SMALL_CELL_SIZE = 50;
export const CELL_MARGIN = 15;

export enum TileType {
  BG,
  VALUE,
  PREVIEW,
  NEXT_0,
  NEXT_1,
}

export type Tile = {
  row: number;
  col: number;
  value: number;
  type: TileType;
  transition?: {
    row: number;
    col: number;
  }
}

export type TileProps = {
  tile: Tile,
  onClick?: (e: React.MouseEvent) => void,
  scale?: number,
}

export const Tile: React.FC<TileProps> = ({tile, onClick, scale = 1}) => {
  const { row, col, type, value, transition } = tile;
  return (
    <div
      className={`tile ${{
        [TileType.VALUE]: 'value',
        [TileType.BG]: 'bg',
        [TileType.PREVIEW]: 'value preview',
        [TileType.NEXT_0]: 'value next next-0',
        [TileType.NEXT_1]: 'value next next-1',
      }[type]} ${value > 0 ? `level-${Math.min(Math.round(Math.log(value) / Math.log(3)), 11)}` : ''}`}
      style={[TileType.NEXT_1, TileType.NEXT_0].includes(type) ? {
        left: CELL_MARGIN + (transition ? transition.col : col) * (SMALL_CELL_SIZE + CELL_MARGIN),
        top: CELL_MARGIN + (transition ? transition.row : row) * (CELL_SIZE + CELL_MARGIN),
        transform: (transition ? transition.col !== 1 : col !== 1) ? 'scale(0.5, 0.5)' : 'scale(1, 1)',
        opacity: (transition ? (transition.col < 0 || transition.col > 1) : (col < 0 || col > 1)) ? 0 : 1,
        ...transition ? {} : {transition: 'none'}
      } : {
        left: (CELL_MARGIN + (transition ? transition.col : col) * (CELL_SIZE + CELL_MARGIN)) * scale,
        top: (CELL_MARGIN + (transition ? transition.row : row) * (CELL_SIZE + CELL_MARGIN)) * scale,
        transform: transition?.col === col && transition?.row === row ? `scale(${1.05 * scale}, ${1.05 * scale})` : `scale(${scale}, ${scale})`,
        transformOrigin: 'left top',
      }}
      onClick={onClick}
    >
      {value > 0 ? value : null}
    </div>
  )
};