import React from "react";
import './Guide.css';
import {GuideStatus, TripleAction, TripleState} from "./TripleReducer";

export type GuideProps = {
  store: TripleState,
  dispatcher: React.Dispatch<TripleAction>,
}

export const Guide: React.FC<GuideProps> = ({ store, dispatcher}) => {
  return (
    <div className={'guide'}>
      {
        store.guideStatus <= GuideStatus.THIRD_TAP && (
          <div
            className={'tap-gesture'}
            style={{
              left: store.guideStatus === GuideStatus.FIRST_TAP ? '54px' :
                store.guideStatus === GuideStatus.SECOND_TAP ? '175px' : '296px',
              top: '54px',
            }}
          />
        )
      }
      {
        store.guideStatus === GuideStatus.NEXT_TIP && (
          <div className={'next-tip'}/>
        )
      }
      {
        store.guideStatus > GuideStatus.THIRD_TAP && store.guideStatus < GuideStatus.COMPLETED ? (
          <div className={'fullscreen'} onClick={() => dispatcher({
            action: 'guide_next'
          })}/>
        ) : null
      }
      <div className={'tooltip'}>
        {
          {
            [GuideStatus.FIRST_TAP]: '轻点空格以放置数字',
            [GuideStatus.SECOND_TAP]: '再放置一个',
            [GuideStatus.THIRD_TAP]: '让三个数字连在一起',
            [GuideStatus.NEXT_TIP]: '棒极了！你的下一个数字会显示在这里',
            [GuideStatus.GOAL]: '让我们以合成 729 为目标吧👻',
            [GuideStatus.COMPLETED]: '',
          }[store.guideStatus]
        }
      </div>
    </div>
  )
}